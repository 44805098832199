import { getCampusRecruitment } from "../../redux/features/drives/getCampusRecruitmentSlice";
import CreateDriveModal from "../../components/drives/modals/CreateDriveModal";
import { useDispatch, useSelector } from "react-redux";
import { DriveColumns } from "../../components/drives/columns/DriveColumns";
import { useEffect, useRef, useState } from "react";
import CommonOutlet from "../../components/reusable/layout/CommonOutlet";
import { DataTable } from "../../components/table/DataTable";
import { getDriveTemplates } from "../../redux/features/drive-template/getDriveTemplateDetailsSlice";
import { getJobs } from "../../redux/features/jobs/getJobSlice";
import { useNavigate } from "react-router-dom";
import ListLoading from "../../components/reusable/loaders/ListLoading";
import { getDriveStatus } from "../../redux/features/drives/getDriveStatusSlice";
import { getDriveTypes } from "../../redux/features/drives/getDriveTypeSlice";
import { getInviteCollege } from "../../redux/features/college/getInviteCollegeSlice";
import snackbar from "../../components/reusable/snackbar";
import {
  resetCreateDriveError,
  resetCreateDriveSuccess,
} from "../../redux/features/drives/createDriveSlice";
import { ToastEnum } from "../../utils/enums";
import { formatDriveStatus } from "../../../src/utils/formatDriveStatus";
import {
  resetAcceptDriveInviteError,
  resetAcceptDriveInviteSuccess,
} from "../../../src/redux/features/drives/acceptDriveInviteSlice";
import {
  resetRejectDriveInviteError,
  resetRejectDriveInviteSuccess,
} from "../../../src/redux/features/drives/rejectDriveInviteSlice";

import ModalLoader from "../../../src/components/reusable/loaders/ModalLoader";

const Drives = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const timeoutRef = useRef();
  const { loading, drives } = useSelector(
    (state) => state.campusRecruitmentReducer
  );
  const { driveTemplates } = useSelector((state) => state.driveTemplateReducer);
  const { jobs } = useSelector((state) => state.jobsReducer);
  const { driveStatuses } = useSelector((state) => state.driveStatusReducer);
  const { driveTypes } = useSelector((state) => state.driveTypeReducer);
  const { colleges } = useSelector((state) => state.collegeInviteReducer);
  const [selectedDriveStatus, setSelectedDriveStatus] = useState(null);
  const {
    successMessage: acceptDriveSuccessMsg,
    error: acceptDriveError,
    loading: acceptDriveLoading,
  } = useSelector((state) => state.acceptDriveInviteReducer);
  const { successMessage: rejectDriveSuccessMsg, error: rejectDriveError } =
    useSelector((state) => state.rejectDriveInviteReducer);
  const [loadingModalOpen, setloadingModalOpen] = useState(false);

  const { successMessage, error } = useSelector(
    (state) => state.createDriveReducer
  );

  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  useEffect(() => {
    if (successMessage) {
      snackbar(ToastEnum.SUCCESS, successMessage);
      dispatch(resetCreateDriveSuccess());
    }
    if (error) {
      snackbar(ToastEnum.ERROR);
      dispatch(resetCreateDriveError());
    }

    dispatch(getDriveTemplates());
    dispatch(getJobs());
    dispatch(getDriveStatus());
    dispatch(getDriveTypes());
    // dispatch(getCollegeBranch())
    dispatch(getInviteCollege());
  }, [successMessage, error, pagination]);

  useEffect(() => {
    if (acceptDriveSuccessMsg) {
      snackbar(ToastEnum.SUCCESS, acceptDriveSuccessMsg);
      dispatch(resetAcceptDriveInviteSuccess());
    }
    if (acceptDriveError) {
      snackbar(ToastEnum.ERROR, acceptDriveError);
      dispatch(resetAcceptDriveInviteError());
    }
    if (rejectDriveSuccessMsg) {
      snackbar(ToastEnum.SUCCESS, rejectDriveSuccessMsg);
      dispatch(resetRejectDriveInviteSuccess());
    }
    if (rejectDriveError) {
      snackbar(ToastEnum.ERROR, rejectDriveError);
      dispatch(resetRejectDriveInviteError());
    }
    const { pageSize, pageIndex } = pagination;
    dispatch(
      getCampusRecruitment({
        name: name,
        limit: pageSize,
        offset: pageIndex * pageSize,
        driveStatus: selectedDriveStatus,
      })
    );
  }, [
    successMessage,
    error,
    pagination,
    acceptDriveSuccessMsg,
    selectedDriveStatus,
    acceptDriveError,
    rejectDriveSuccessMsg,
    rejectDriveError,
  ]);

  const templateItems = driveTemplates?.results?.map((template) => {
    return {
      value: template.id,
      label: template.title,
    };
  });
  const jobTitleItems = jobs?.results?.map((job) => {
    return {
      value: job.id,
      label: job.title,
    };
  });

  const collegesItems = colleges.map((college) => {
    return {
      value: college.id,
      label: college.title,
    };
  });
  const statusItems = driveStatuses.map((status) => {
    return {
      value: status.id,
      label: formatDriveStatus(status.label),
    };
  });
  const driveTypeItems = driveTypes.map((type) => {
    return {
      value: type.id,
      label: type.label,
    };
  });

  const navigate = useNavigate();
  const handleRowClick = (row) => {
    const path = `/drive-details/${row.id}`;
    navigate(path);
  };

  const handleSearchChange = (e) => {
    const debounceDelay = 500;
    setName(e.target.value);
    // Clear previous timeout if exists
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    // Set new timeout
    timeoutRef.current = setTimeout(() => {
      handleSearchChange.timeoutId = setTimeout(() => {
        dispatch(getCampusRecruitment({ name: e.target.value }));
      }, debounceDelay);
    }, debounceDelay);
  };

  useEffect(() => {
    if (acceptDriveLoading) {
      setloadingModalOpen(true); // Open modal when loading is true
    } else {
      setloadingModalOpen(false); // Close modal when loading is false
    }
  }, [acceptDriveLoading]);

  const statusFilterChange = (value) => {
    setSelectedDriveStatus(value);
  };

  return (
    <div>
      <CommonOutlet
        hasSearch={true}
        placeholder={"Search for drives"}
        title={"Drive Databases"}
        selectPlaceholder={"Filter by"}
        handleFilterChange={statusFilterChange}
        handleSearchChange={handleSearchChange}
        selectedValue={selectedDriveStatus}
        filterItems={statusItems}
        createButton={
          <CreateDriveModal
            collegeDropdownItems={collegesItems}
            driveTypeDropdownItems={driveTypeItems}
            jobTitleDropdownItems={jobTitleItems}
            templateDropdownItems={templateItems}
          />
        }
      >
        {loading ? (
          <ListLoading />
        ) : (
          drives && (
            <DataTable
              hasClick={true}
              pagination={pagination}
              setPagination={setPagination}
              onClickRoute={handleRowClick}
              columns={DriveColumns({ jobTitleItems, templateItems })}
              data={drives}
            />
          )
        )}
      </CommonOutlet>

      {/* Loading modal */}
      <ModalLoader open={loadingModalOpen} setOpen={setloadingModalOpen}>
        <p>Creating Drive...</p>
      </ModalLoader>
    </div>
  );
};
export default Drives;
