import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";
import axios from "axios";
import { BFF_URL } from '../../../utils/constants';

export const createJobForm = createAsyncThunk('createJobForm', async (obj, { rejectWithValue }) => {
    try {
        const customConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        await axios.post(
            new URL('job-form', BFF_URL).toString(),
            obj.body,
            customConfig
        );
    } catch (error) {
        console.log(error.message)
        return rejectWithValue({
            message: error.message,
            code: error.code,
            status: error.response?.status,
            data: error.response?.data,
            error:error
        })
    }
})


export const fetchJobFormCollegeDetails = createAsyncThunk('fetchJobFormCollegeDetails', async (institutionId, { rejectWithValue }) => {
    try {
        const response = await axios.get((new URL(`colleges/${institutionId}`, `${BFF_URL}`)).toString());
        return response.data;
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})

const createJobFormSlice = createSlice({
    name: "Jobs",
    initialState: {
        loading: true,
        error: null,
        successMessage:null,
        college: null,
    },
    extraReducers: (builder) => {
        builder.addCase(createJobForm.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(createJobForm.fulfilled, (state, action) => {
            state.loading = false;
            state.successMessage = "Form Created"
        })
        builder.addCase(createJobForm.rejected, (state, action) => {
            state.loading = false;
        })


        builder.addCase(fetchJobFormCollegeDetails.pending, (state, action) => {
            state.loading = true;
        })
        builder.addCase(fetchJobFormCollegeDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.college = action.payload;
        })
        builder.addCase(fetchJobFormCollegeDetails.rejected, (state, action) => {
            state.loading = false;
            state.college = null;
            state.error = "Error fetching college details"
        })
    }
});

export default createJobFormSlice.reducer