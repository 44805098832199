import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../../services/ApiService";
import axios from "axios";
import { BFF_URL } from '../../../utils/constants';


export const getJobFormInstance = createAsyncThunk('fetchJobFormInstance', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.get(
            new URL(`/job-form-instance/${id}`, BFF_URL).toString()
        );
        return response.data
    } catch (error) {
        console.log(error)
        return rejectWithValue(error)
    }
})


const getJobFormInstanceSlice = createSlice({
    name: "Jobs",
    initialState: {
        loading: true,
        error: null,
        successMessage:null,
        jobFormInstance:null
    },
    extraReducers: (builder) => {

        // fetchJobFormInstance reducers
        builder.addCase(getJobFormInstance.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getJobFormInstance.fulfilled, (state, action) => {
            state.loading = false;
            state.jobFormInstance = { ...action.payload };
        });
        builder.addCase(getJobFormInstance.rejected, (state, action) => {
            state.loading = false;
            state.error = "Error fetching job form instance";
            state.jobFormInstance = null;
        });

    }
});

export default getJobFormInstanceSlice.reducer