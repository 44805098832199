import navigateBack from "../../utils/backNavigateUtil";
import { Icons } from "../../utils/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "../ui/icon";
import { useDispatch, useSelector } from "react-redux";
import CreateDriveModal from "../drives/modals/CreateDriveModal";
import ConnectionButton from "./NetworkConnectionButton";
import { useEffect, useState } from "react";
import { getJobs } from "../../redux/features/jobs/getJobSlice";
import { getDriveTemplates } from "../../redux/features/drive-template/getDriveTemplateDetailsSlice";
import { getDriveTypes } from "../../redux/features/drives/getDriveTypeSlice";
import { getInviteCollege } from "../../redux/features/college/getInviteCollegeSlice";
import { connectionStatusEnum } from "../../utils/enums";
const NetworkDetailsHeader = () => {
  const dispatch = useDispatch();
  const { networkDetails } = useSelector(
    (state) => state.networkDetailsReducer
  );
  const connectionStatus = networkDetails?.connectionStatus;

  const { driveTemplates } = useSelector((state) => state.driveTemplateReducer);
  const { jobs } = useSelector((state) => state.jobsReducer);
  const { driveTypes } = useSelector((state) => state.driveTypeReducer);
  const { colleges } = useSelector((state) => state.collegeInviteReducer);
  const { collegeId } = useParams()

  useEffect(() => {
    dispatch(getDriveTemplates());
    dispatch(getJobs());
    dispatch(getDriveTypes());
    dispatch(getInviteCollege());
  }, []);

  const templateItems = driveTemplates?.results?.map((template) => {
    return {
      value: template.id,
      label: template.title,
    };
  });
  const jobTitleItems = jobs?.results?.map((job) => {
    return {
      value: job.id,
      label: job.title,
    };
  });
  const collegesItems = colleges.map((college) => {
    return {
      value: college.id,
      label: college.title,
    };
  });
  const driveTypeItems = driveTypes.map((type) => {
    return {
      value: type.id,
      label: type.label,
    };
  });
  const navigate = useNavigate();

  return (
    <div className="relative">
      <div className="flex items-center cursor-pointer hover:bg-primaryButtonColor/90 justify-center absolute -top-2 -left-4 w-10 h-10 rounded-full bg-primaryButtonColor p-2">
        <Icon
          onClick={() => navigateBack(navigate)}
          icon={Icons.BACKARROW}
          className="text-lightColor w-5 h-5"
        />
      </div>
      <div className="shadow-lg rounded-lg px-6 py-6 border">
        <div className="flex items-center gap-x-2">
          <img
            className="w-20 h-20 rounded-full object-cover"
            src={networkDetails?.college.logoUrl}
            alt=""
          />
          <div className="flex flex-col">
            <h1 className="text-2xl font-semibold">
              {networkDetails?.college?.title}
            </h1>
            <p>{networkDetails?.college?.address}</p>
          </div>
        </div>
        <div className="flex justify-end items-center gap-x-2 -mt-4">
          <ConnectionButton />
          {connectionStatus === connectionStatusEnum.ACCEPTED &&
          collegeId.length > 0 ? (
            <CreateDriveModal
              collegeId={networkDetails?.college.id}
              collegeDropdownItems={collegesItems}
              driveTypeDropdownItems={driveTypeItems}
              jobTitleDropdownItems={jobTitleItems}
              templateDropdownItems={templateItems}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default NetworkDetailsHeader;
