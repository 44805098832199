import { DataTable } from "../../components/table/DataTable"
import CommonOutlet from "../../components/reusable/layout/CommonOutlet"
import ListLoading from "../../components/reusable/loaders/ListLoading"
import AddMemberModal from "../../components/team/AddMemberModal"
import { getTeam } from "../../redux/features/team/getTeamSlice"
import { useDispatch, useSelector } from "react-redux"
import { MyTeamColumns } from "../../components/team/columns/MyTeamColumns"
import { useEffect, useRef, useState } from "react"
import snackbar from "../../components/reusable/snackbar"
import { ToastEnum } from "../../utils/enums"
import { resetMemberAddError, resetMemberAddSuccessMsg } from "../../redux/features/team/addMemberSlice"
import { getMemberDesignation } from "../../redux/features/team/getMemberDesignationSlice"

const Team = () => {

  const { members, loading } = useSelector(state => state.getTeamReducer)
  const { successMsg, error } = useSelector(state => state.addMemberReducer)
  const { memberDesignation } = useSelector(state => state.designationReducer)

  const designationSet = new Set(memberDesignation)
  const [name, setName] = useState("")
  const timeoutRef = useRef(null)
  const dispatch = useDispatch()
  useEffect(() => {
    if (successMsg) {
      snackbar(ToastEnum.SUCCESS, successMsg)
      dispatch(resetMemberAddSuccessMsg())
    }
    if (error) {
      snackbar(ToastEnum.ERROR, error)
      dispatch(resetMemberAddError())
    }
    dispatch(getTeam())
    dispatch(getMemberDesignation())
  }, [successMsg, error])

  const handleSearchChange = (e) => {
    const debounceDelay = 500;
    setName(e.target.value)
    // Clear previous timeout if exists
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    // Set new timeout
    timeoutRef.current = setTimeout(() => {
      handleSearchChange.timeoutId = setTimeout(() => {
        dispatch(getTeam({ name: e.target.value }))
      }, debounceDelay);
    }, debounceDelay);
  }

  const designationFilterItems = Array.from(designationSet).map((designation) => {
    return {
      label: designation,
      value: designation
    }
  })

  const statusFilterChange = (value) => {
    dispatch(getTeam({ name: name, designation: value }))
  }
  return (
    <div>
      <CommonOutlet
        hasSearch={true}
        placeholder={"Search for members"}
        title={"My Team"}
        selectPlaceholder={"Filter by Designation"}
        handleFilterChange={statusFilterChange}
        handleSearchChange={handleSearchChange}
        filterItems={designationFilterItems}
        createButton={
          <AddMemberModal />
        }>
        {
          loading ? <ListLoading /> : members && <DataTable hasClick={false} hasPagination={false} columns={MyTeamColumns} data={members} />
        }
      </CommonOutlet>
    </div>)
}
export default Team