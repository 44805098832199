import NetworkDetailsHeader from "../../components/networks/NetworkDetailsHeader"
import { getCollegeNetworkDetails } from "../../redux/features/networks/getCollegeNetworkDetailsSlice"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import NetworkDetailsInfo from "../../components/networks/NetworkDetailsInfo"
import NetworkDetailsLoader from "../../components/reusable/loaders/NetworkDetailsLoader"
import { getJobFormInstance } from "../../redux/features/jobs_form/getJobFormInstanceSlice"

const NetworkDetails = () => {

  const { jobFormInstance,loading} = useSelector(
    (state) => state.getJobFormInstanceReducer
  );  
  const dispatch = useDispatch()
  const { loading:collegeLoading } = useSelector(state => state.networkDetailsReducer)
  const { collegeId,id } = useParams()

  useEffect(() => {
    if (id) {
      dispatch(getJobFormInstance(id));
    }
  
    if (collegeId) {
      dispatch(getCollegeNetworkDetails(collegeId))
    }
  
    if (id && jobFormInstance?.institution_id) {
      dispatch(getCollegeNetworkDetails(jobFormInstance.institution_id));
    }
  }, [id, jobFormInstance?.institution_id]);
  

  return (
    <div>
      {
        loading || collegeLoading? <NetworkDetailsLoader /> :
          <>
            <NetworkDetailsHeader />
            <NetworkDetailsInfo />
          </>
      }
    </div>
  )
}
export default NetworkDetails