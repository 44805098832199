import { Loader } from "lucide-react";
import CommonModal from "../modals/CommonModal";

const ModalLoader = ({ open, setOpen, children }) => {
  return (
    <CommonModal
      className="max-w-[300px]"
      hasCloseIcon={false}
      open={open}
      setOpen={setOpen}
    >
      <div className="flex flex-col gap-y-4 items-center">
        <Loader className="animate-spin" />
        {children}
      </div>
    </CommonModal>
  );
};
export default ModalLoader;
