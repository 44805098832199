import { useDispatch, useSelector } from "react-redux"
import CommonOutletSearch from "../../reusable/layout/CommonOutletSearch"
import { JobApplicantsColumns } from "../columns/JobApplicantsColumns"
import { DataTable } from "../../table/DataTable"
import { getCollegeNetworkDetails } from "../../../redux/features/networks/getCollegeNetworkDetailsSlice"
import { useEffect } from "react"
import ListLoading from "../../reusable/loaders/ListLoading"

const JobDetailsApplicants = ({ pagination, setPagination }) => {
  const dispatch = useDispatch()
  const { jobApplications, loading } = useSelector(state => state.jobApplicationReducer)
  const { networkDetails } = useSelector(state => state.networkDetailsReducer)
  const { loading:collegeLoading } = useSelector(state => state.networkDetailsReducer)
 
  useEffect(() => {
    if (jobApplications?.length) {
      jobApplications.forEach(({ student }) => {
        const institutionId = student?.institutionId;
        if (institutionId) {
          dispatch(getCollegeNetworkDetails(institutionId));
        }
      });
    }
  }, [])

  const enhancedApplications = jobApplications?.map((application) => {
    const institutionId = application.student?.institutionId;
    const collegeName = institutionId ? networkDetails?.college?.title : "";
    return { ...application, college: collegeName };
  });


  return (
    <div className="border shadow-xl px-8 py-4 rounded-3xl rounded-tl-none">
      <CommonOutletSearch placeholder={"Search for applicants"} selectPlaceholder={"Filter by: College Name"} filterItems={[]} />

      {
          loading || collegeLoading ? <ListLoading /> : jobApplications && <DataTable hasClick={false} pagination={pagination} setPagination={setPagination} columns={JobApplicantsColumns} data={enhancedApplications} />
      }
    </div>)
}
export default JobDetailsApplicants