import { Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import Drives from './pages/drives/drives';
import Jobs from './pages/jobs/jobs';
import Networks from './pages/networks/networks';
import Layout from './pages/layout/Layout';
import Analytics from './pages/analytics/Analytics';
import { Login } from './pages/Login';
import ProtectedRoute from './utils/protectedRoutes';
import PublicRoute from './utils/publicRoutes';
import DriveTemplates from './pages/drives/drive-template';
import DriveTemplateDetails from './pages/drives/DriveTemplateDetails';
import DriveDetails from './pages/drives/drive-details';
import NetworkDetails from './pages/networks/network-details';
import JobDetails from './pages/jobs/job-details';
import ApplicantDetails from './pages/applicant-details';
import Profile from './pages/profile/Profile';
import Team from './pages/team/Team';
import TeamDetails from './pages/team/TeamDetails';
import JobFormPage from './pages/jobs/JobFormPage';
import Forgot from './pages/Forgot';
import { useSelector } from 'react-redux';

export const RouterFn = () => {
  const { user } = useSelector((state) => state.authReducer)
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route
          path="/"
          element={<Layout />}
          children={[
            <Route path="/" element={<Home />} />,
            <Route path="/drives" element={<Drives />} />,
            <Route path="/drive-details/:driveId" element={<DriveDetails />} />,
            <Route path="/jobs" element={<Jobs />} />,
            <Route path="/job-details/:jobId" element={<JobDetails />} />,
            <Route
              path="/applicant-details/:applicationId"
              element={<ApplicantDetails />}
            />,
            <Route path="/networks" element={<Networks />} />,
            <Route
              path="/college-details/:collegeId"
              element={<NetworkDetails />}
            />,
            <Route path="/analytics" element={<Analytics />} />,
            <Route path="/templates" element={<DriveTemplates />} />,
            <Route path="/templates/:id" element={<DriveTemplateDetails />} />,
            <Route path="/profile" element={<Profile />} />,
            <Route path="/profile" element={<Profile />} />,
            <Route path="/my-team" element={<Team />} />,
            <Route path="/team-details/:memberId" element={<TeamDetails />} />,
          ]}
        ></Route>
      </Route>
      
      {user && (
        <Route element={<ProtectedRoute />}>
           <Route
          path="/"
          element={<Layout />}
          children={[
           <Route path="/job-form/:id" element={<NetworkDetails />} />
          ]} />
        </Route>
      )}
       {/* Public Routes */}

       {!user && (
        <Route element={<PublicRoute />}>
          <Route path="/job-form/:id" element={<JobFormPage />} />
        </Route>
      )}
      
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<Forgot />} />
    </Routes>
  );
};
